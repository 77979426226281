<template>
  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
    <div
      class="d-flex flex-column flex-grow-1 grey lighten-4 "
      style="border-radius: 3px;"
      v-if="failedTransactions.length > 0"
    >
      <span
        class=" font font-size-md ml-2 mt-2 text-uppercase font-weight-medium"
        >All Failed Transactions</span
      >

      <div
        v-for="(transaction, index) in failedTransactions"
        :key="index"
        class="d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 box-shadow-light"
      >
        <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            rules="required"
            class="d-flex flex-grow-1"
          >
            <v-text-field
              autofocus
              label="Event Title"
              prepend-icon="badge"
              class="font font-weight-medium font-size-md"
              dense
              v-model="transaction.name"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            rules="required"
            class="d-flex flex-grow-1"
          >
            <v-text-field
              label="Amount/Value"
              prepend-icon="credit_card"
              :prefix="
                currency && currency.length > 0 ? currency[0].symbol : 'GHS'
              "
              class="font font-weight-medium font-size-md"
              dense
              v-model="transaction.amount"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
          <ValidationProvider tag="div" class="d-flex flex-grow-1">
            <v-text-field
              label="Comment"
              prepend-icon="edit"
              v-model="transaction.comment"
              class="font font-weight-medium font-size-md"
              dense
            />
          </ValidationProvider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="red"
                @click.stop="removeFailedTransaction(index)"
                small
              >
                <i class="material-icons-outlined">remove</i>
              </v-btn>
            </template>
            <span class="font font-weight-medium font-size-sm"
              >Remove Item</span
            >
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex flex-row justify-end my-2 mr-2 mb-2 flex-grow-1">
        <v-btn @click="addFailedTransaction" small color="primary">
          <span
            class="font font-weight-medium font-size-sm"
            style="font-size: 12px !important;"
          >
            Add Failed transactions
          </span>
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/auction.svg" style="width: 80px;" />
      <span class="font font-weight-medium"
        >Record any other failed transactions as part of the daily sales here
        <kbd class="ml-1">{{ date }}</kbd></span
      >
      <span class="font font-weight-medium font-size-sm"
        >Failed transactions may include failed credit card, visa card
        transactions etc.</span
      >
      <v-btn @click="addFailedTransaction" small color="primary" class="mt-2">
        <span
          class="font font-weight-medium font-size-sm"
          style="font-size: 12px !important;"
          >Add Failed Transactions</span
        >
      </v-btn>
    </div>
  </div>
</template>
<script>
import { required } from "vee-validate/dist/rules";
import { ValidationProvider, extend } from "vee-validate";
extend("required", {
  ...required,
  message: "Input field is required"
});
export default {
  name: "RecordOccurrences",
  components: { ValidationProvider },
  props: {
    date: String,
    currency: {
      type: Array,
      default: () => []
    },
    failedTransactions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addFailedTransaction() {
      this.$emit("addFailedTransaction", { name: "", amount: "", comment: "" });
    },
    removeFailedTransaction(index) {
      this.$emit("removeFailedTransaction", index);
    },
    addAllOccurrences() {}
  }
};
</script>
